import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import NavBar from './NavBar'



import '../assets/css/home_new.css';
import '../assets/css/home.css';
import '../assets/css/animation.css'
import '../assets/css/landingpage.css';
import '../assets/css/creators.css';
import LandingPageFooter from './LandingPageFooter';
//import DotsImagesMobileCarousel from './BlurredImages';


import header_right from '../assets/images/landing_v2/header-right.png';

import header_right_mobile from '../assets/images/landing_v2/header-right-mobile.png';

import making_notes from '../assets/images/landing_v2/making_notes.png';
import online_store from '../assets/images/landing_v2/online_store.png';
import euro_money from '../assets/images/landing_v2/euro_money.png';


import piggy_bank from '../assets/images/landing_v2/piggy-bank.gif';
import face_mask from '../assets/images/landing_v2/face-mask.gif';

import recipe_book from '../assets/images/landing_v2/recipe-book.gif';
import alarm_clock from '../assets/images/landing_v2/alarm-clock.gif';

import family_time from '../assets/images/landing_v2/family-time.png';
import books_stack from '../assets/images/landing_v2/books-stack.png';
import travel_bag from '../assets/images/landing_v2/travel-bag.png';
import language_learning from '../assets/images/landing_v2/language-learning.png';
import friendly_ghost from '../assets/images/landing_v2/friendly-ghost.png';



import suitcase from '../assets/images/landing_v2/suitcase.png';
import positive_dynamic from '../assets/images/landing_v2/positive_dynamic.png';
import signpost from '../assets/images/landing_v2/signpost.png';
import romance from '../assets/images/landing_v2/romance.png';
import rainbow from '../assets/images/landing_v2/rainbow.png';
import classroom from '../assets/images/landing_v2/classroom.png';

import section_3_1 from '../assets/images/landing_v2/section_3_1.png';
import section_3_2 from '../assets/images/landing_v2/section_3_2.png';

import section_4_right from '../assets/images/landing_v2/section_4_right.png';

import right_mobile from '../assets/images/landing_v2/right_mobile.png';





//import TextSlider from './TextSlider';

// App Component
function CreatorsPage() {


    const App_url = 'https://learnee.page.link/rniX'


    const gridItems = [
        { id: 1, className: "piggy-bank", type: "gif", src: piggy_bank, alt: "Piggy Bank" },
        { id: 2, className: "books-stack", type: "image", src: books_stack, alt: "Books Stack" },
        { id: 3, className: "face-mask", type: "gif", src: face_mask, alt: "Face Mask GIF" },
        { id: 4, className: "travel-bag", type: "image", src: travel_bag, alt: "Travel Bag" },
        { id: 5, className: "recipe-book", type: "gif", src: recipe_book, alt: "Recipe Book GIF" },
        { id: 6, className: "language-learning", type: "image", src: language_learning, alt: "Language Learning" },
        { id: 7, className: "friendly-ghost", type: "image", src: friendly_ghost, alt: "Friendly Ghost GIF" },
        { id: 8, className: "family-time", type: "image", src: family_time, alt: "Family Time" },
        { id: 9, className: "alarm-clock", type: "gif", src: alarm_clock, alt: "Alarm Clock GIF" },
    ];



    useEffect(() => {
        const navbar = document.querySelector('.navbar');
        if (navbar) {
            navbar.classList.remove('hidden');
        }
    }, []);


    const handleButtonClick = (action, label) => {
        // Track the button click event
        ReactGA.event({
            category: "Button",
            action: action, //"Download Now",
            label: label //"VaSi App Link",
        });
    };


    return (
        <div className="askvasi-home landing-page download-page" id='askvasi-landing'>
            {/* Navbar */}
            <NavBar />



            {/* Page 6 and beyond */}
            <div className="page-wrapper slide-up-overlay-slide-6 page-6 active" id="page-6">
                {/* Container for the two columns */}

                <div className="hero-section-container">
                    {/* Left Column: Overlay content */}
                    <div className="overlay-content-hero-section">

                        <div className='hero-title'><span className='hero-title-1'>Turn Knowledge into Income</span></div>
                        <p>No expertise required—just share what you know. Start small. Earn big.</p>
                        <a rel="noopener noreferrer" href={App_url}>
                            <button onClick={() => handleButtonClick("Start Creating", "VaSi App Link")} className="cta-button">Start Creating</button></a>
                    </div>

                    {/* Right Column: Blurred images container blurred-images-container-hero-section*/}
                    <div className="blurred-images-container-hero-section">
                        <div className="image-5">
                            <img className='header-right-desktop' src={header_right} loading="lazy" alt="Blurred Dots 5" />
                            <img className='header-right-mobile' src={header_right_mobile} loading="lazy" alt="Blurred Dots 5" />
                        </div>
                    </div>

                </div>
            </div>

            <div className='container'>
                {/* Steps Section */}
                <div className="how-it-works-section">
                    <div className="section-title">Start Creating in 3 Steps</div>
                    <p>From idea to income—simpler than you think</p>
                    <div className="steps-container">
                        <div className="step">
                            <div className="step-circle"><img src={making_notes} alt="making_notes" /></div>
                            <h3>Share Your Knowledge</h3>
                            <p>Package what you know into bite-sized, actionable content - Dot. No fancy skills needed.</p>
                        </div>




                        <div className="step">
                            <div className="step-circle margin-20"><img src={online_store} alt="making_notes" /></div>
                            <h3>Publish & Refine</h3>
                            <p>See what resonates with your audience—and iterate as you grow.</p>
                        </div>



                        <div className="step">
                            <div className="step-circle margin-20"><img src={euro_money} alt="making_notes" /></div>
                            <h3>Earn on Your Terms</h3>
                            <p>Get paid for every Dot purchase.</p>
                        </div>
                    </div>
                </div>


                <div className="beyond-chat-section">
                    <div className="content-container">
                        <div className="image-container">

                            <div className="unique-grid-container">
                                {gridItems.map((item) => (
                                    <div key={item.id} className={`unique-grid-item ${item.className}`}>
                                        {item.type === "gif" ? (
                                            <img src={item.src} alt={item.alt} className="gif-image" />
                                        ) : (
                                            <img src={item.src} alt={item.alt} />
                                        )}
                                    </div>
                                ))}
                            </div>


                        </div>
                        <div className="text-container">
                            <div className="beyond-title">Sell Anything – Your Words, Your Way</div>
                            <p>
                                From guides to personal stories, insights to how-to lists—turn any written experience into value.
                            </p>
                            <ul className="custom-list">
                                <li><img width={24} src={suitcase} alt="Binoculars" /> Travel Tips & Itineraries</li>
                                <li><img width={24} src={positive_dynamic} alt="Binoculars" /> Life Lessons & Personal Growth</li>
                                <li><img width={24} src={classroom} alt="Binoculars" /> Career & Business Advice</li>
                                <li><img width={24} src={signpost} alt="Binoculars" /> How-To Guides & Tutorials </li>
                                <li><img width={24} src={rainbow} alt="Binoculars" /> Journals & Reflections</li>
                                <li><img width={24} src={romance} alt="Binoculars" /> Relationship Advice</li>
                            </ul>
                            {/* <a rel="noopener noreferrer" href={App_url}>
                                <button onClick={() => handleButtonClick("Get Started", "VaSi App Link")} className="cta-button">Get Started</button>
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="beyond-chat-section section-3">
                <div className="content-container">
                    <div className="image-container">
                        <div className="beyond-title beyond-title-1">You don’t need to be an author—just have a story to tell.</div>
                        <div>
                            <img loading="lazy"
                                src={section_3_1}
                                alt="VaSi App Preview"
                                className="beyond-chat-image"
                            />
                        </div>

                    </div>
                    <div className="text-container">
                        <div className="beyond-title beyond-title-2">Share your experiences, insights, or lessons. We’ll help you sell it effortlessly.</div>
                        <div>
                            <img loading="lazy"
                                src={section_3_2}
                                alt="VaSi App Preview"
                                className="beyond-chat-image"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container how-to-create'>

                <div className="beyond-chat-section section-4">
                    <div className="top-level-text">Still not sure what to create?</div>
                    <div className="content-container">
                        <div className="image-container">
                            <div className="beyond-title beyond-title-1">Learn from experts. Explore a World of Inspiration</div>
                            <div className='beyond-sub-title-1'>
                                VaSi’s marketplace is your go-to destination for inspiration and knowledge. Browse through our carefully curated categories. Find the dots that resonate with you
                            </div>

                        </div>
                        <div className="text-container">
                            <div>
                                <img loading="lazy"
                                    src={section_4_right}
                                    alt="VaSi App Preview"
                                    className="beyond-chat-image desktop-right"
                                />
                                <img loading="lazy"
                                    src={right_mobile}
                                    alt="VaSi App Preview"
                                    className="beyond-chat-image mobile-right"
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="explore-dots-section">

                    <div className="section-title">Share your knowledge someone out there needs it.</div>

                    <div className="carousel-container">

                        <div className="view-all">
                            <a rel="noopener noreferrer" href={App_url}>
                                <button onClick={() => handleButtonClick("Start Creating Bottom", "VaSi App Link")} className='cta-button'>Start Creating</button>
                            </a>
                        </div>

                    </div>
                    <div className='footer_bg_middle'></div>

                </div>

            </div>
            <LandingPageFooter />



        </div>

    );
}
export default CreatorsPage;