import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import placeholder_img from '../assets/images/landing-page/no-image.png';
//import noproile_img from '../assets/images/landing-page/no-profile.png';
import { Link } from "react-router-dom";
import {  freeDots } from "./utils"
//import icon_left from '../assets/images/landing-page/circle_arrow_next.png'
//import icon_right from '../assets/images/landing-page/circle_arrow_prev.png'

const DotsCarousel = ({ dotsData }) => {

    // Custom Next Arrow Component
    // const NextArrow = ({ onClick }) => {
    //     return (
    //         <div
    //             className="custom-next-arrow"
    //             onClick={onClick}                
    //         >
    //             <img width={32} src={icon_left} loading="lazy" alt="" />
    //         </div>
    //     );
    // };

    // Custom Prev Arrow Component
    // const PrevArrow = ({ onClick }) => {
    //     return (
    //         <div
    //             className="custom-prev-arrow"
    //             onClick={onClick}
               
    //         >
    //            <img width={32} src={icon_right} loading="lazy" alt="" />
    //         </div>
    //     );
    // };

    const slidesToShow = Math.min(5, dotsData.length);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        gap: 20,
        arrows: false,
        autoplay: true,       
        lazyLoad: true,
        // pauseOnHover: true,
        //prevArrow: <PrevArrow />, // Add the custom left arrow component
        //nextArrow: <NextArrow />, // Add the custom right arrow component
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: Math.min(4, dotsData.length),
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: Math.min(4, dotsData.length),
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(4, dotsData.length),
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    return (
        <div className="similar-dots-container slider-container">
            <Slider {...settings} className="dots-carousel">
                {dotsData.map((dot, index) => (

                    <Link target='_blank' to={`/dots?mktDotId=${dot.mktPlaceId}`} key={index}>
                        <div className="dot-item" key={index}>
                            {/* Check if dotMedia exists and has at least one item */}
                            {(dot.payload?.dotMedia?.length > 0 || dot.dotMedia?.length > 0) &&
                                (dot.payload?.dotMedia?.[0]?.type === 4 || dot.dotMedia?.[0]?.type === 4) ? (
                                <img className="dot-image"
                                    src={dot.payload?.dotMedia?.[0]?.thumbUrl || dot.dotMedia?.[0]?.thumbUrl}
                                    alt="Dot Thumbnail"
                                />
                            ) : (
                                <img className="dot-image" src={placeholder_img} alt="Dot Thumbnail" />
                            )}
                            <div className="similar-dots-overlay">
                                <div className="similar-dot-title">{dot.dotTitle || "Untitled Dot"}</div>
                                <div className="similar-dot-creator">
                                    <span className='font-weight-600'>&nbsp;{typeof dot.price === 'undefined' ? 'Free' : freeDots(dot.price)}&nbsp;</span>
                                    {/* {dot.payload?.userAvatar || dot.userAvatar ? (<img className="similar-dot-user-avatar" src={dot.payload?.userAvatar || dot.userAvatar} alt="Dot Thumbnail" />
                                    ) : (
                                        <img className="similar-dot-user-avatar" src={noproile_img} alt="Dot Thumbnail" />
                                    )}&nbsp; {dot.creator || "Unknown Creator"} */}
                                </div>
                            </div>
                        </div>
                    </Link>

                    // <div className="dot-item" key={index}>
                    //     <img
                    //         src={dot.image}
                    //         alt={dot.title}
                    //         className="dot-image"
                    //     />
                    //     <div className="similar-dots-overlay">
                    //         <div className="similar-dot-title">{dot.title || "Untitled Dot"}</div>
                    //         <div className="similar-dot-creator">
                    //             <img className="similar-dot-user-avatar" src={placeholder_img} alt="Dot Thumbnail" />
                    //             &nbsp;{dot.author}
                    //         </div>
                    //     </div>


                    // </div>
                ))}
            </Slider>
        </div>
    );
};

export default DotsCarousel;