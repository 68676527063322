import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import Axios from "axios";
import defaultAvatar from "../../assets/images/mystery-man.jpg"
import { formatWithMonthName, freeDots } from "../utils"
import ActionModal from "./ActionModal"; // Import ActionModal
import { getAuth } from "firebase/auth";

function MarketplaceList(props) {

    const [isButtonDisabledPopular, setButtonDisabledPopular] = useState({});
    const [isButtonDisabledFeatured, setButtonDisabledFeatured] = useState({});
    const [isButtonDisabledPromoted, setButtonDisabledPromoted] = useState({});
    const [isButtonDisabledDots, setButtonDisabledDots] = useState({});
    const [isButtonDisabledDynamicLink, setButtonDisabledDynamicLink] = useState({});
    const [successStates, setSuccessStates] = useState({
        featured: {},
        populared: {},
        promoted: {}
    });

    // State variable to store the generated dynamic link
    const [dynamicLinks, setDynamicLinks] = useState({});

    const [data, setData] = useState(props.data);
    const [selectedItem, setSelectedItem] = useState(null);
    const [modalIndex, setModalIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);

    //const [isArchived,] = useState(props.isArchived);

    const updateMarketplace_URL = process.env.REACT_APP_BACKENDURL + 'manageMarketPlaceDotsViaAdmin';


    // Modal open/close handlers
    const openModal = (item, index) => {
        setSelectedItem(item);
        setModalIndex(index);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedItem(null);
        setModalIndex(null);
        setShowModal(false);
    };

    const auth = getAuth();
    localStorage.setItem('userAuth', auth.currentUser.accessToken);


    // Use useEffect to listen for changes in props
    useEffect(() => {


        const mktPlaceIdLocal = localStorage.getItem('mktPlaceId');
        console.log(mktPlaceIdLocal);

        if (mktPlaceIdLocal) {
            // Create a new array without the item at the specified index
            const updatedItems = data.filter(item => item.mktPlaceId !== mktPlaceIdLocal);

            //const updatedItems = props.data.filter((_, mktPlaceId) => mktPlaceId !== mktPlaceIdLocal);
            if (updatedItems.length > 0 || data.length === 1) {
                //localStorage.removeItem('mktPlaceId');
                // Update the state to reflect the new array
                setData(updatedItems);
            } else {
                // Update the state when props change
                setData(props.data);
            }

        } else {
            // Update the state when props change
            // Update the state only if props.data has changed
            if (JSON.stringify(props.data) !== JSON.stringify(data)) {
                setData(props.data);
            }
        }

    }, [props.data]); // Only update when props.myProp changes , data



    const togglePopularity = async (itemId, newIsPopular, id) => {

        setData((prevItems) =>
            prevItems.map((item) =>
                item.mktPlaceId === itemId ? { ...item, isPopular: newIsPopular } : item
            )
        );

        const ourRequest = Axios.CancelToken.source();
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + auth.currentUser.accessToken
        }
        setButtonDisabledPopular({ [id]: true });
        try {
            const response = await Axios.post(updateMarketplace_URL, {
                data: {
                    "marketPlaceId": itemId,
                    "isPopular": newIsPopular
                }
            },
                { cancelToken: ourRequest.token }
            );
            const json = await response.data;
            if (json.result.status === 200) {

                // Update the success state for this button
                setSuccessStates((prev) => ({
                    ...prev,
                    populared: {
                        ...prev.populared,
                        [id]: newIsPopular // Mark this button as successful
                    }
                }));

                data.isPopular = newIsPopular
                setButtonDisabledPopular({ [id]: false });
            } else {
                alert(json.result.message)
                setButtonDisabledPopular({ [id]: false });
            }
        } catch (er) {

            setButtonDisabledPopular({ [id]: false });
            //console.log("There was a problem or the request was cancelled." + er)
        }

        //console.log(showToast)

    }


    const toggleDisable = async (itemId, newIsActive, id) => {

        const actionText = (newIsActive === false) ? 'disable' : 'enable';

        const shouldDelete = window.confirm(`Are you sure you want to ${actionText} this Dots?`);
        if (shouldDelete) {

            setData((prevItems) =>
                prevItems.map((item) =>
                    item.mktPlaceId === itemId ? { ...item, isActive: newIsActive } : item
                )
            );

            const ourRequest = Axios.CancelToken.source();
            //console.log("data==", data)
            setButtonDisabledDots({ [id]: true });
            try {
                const response = await Axios.post(updateMarketplace_URL, {
                    data: {
                        "marketPlaceId": itemId,
                        "isActive": newIsActive
                    }
                },
                    { cancelToken: ourRequest.token }
                );
                const json = await response.data;
                if (json.result.status === 200) {
                    const updatedItems = data.filter(item => item.mktPlaceId !== itemId);
                    setData(updatedItems);

                    //data.isActive = newIsActive;
                    //console.log("data1===", data)
                    setButtonDisabledDots({ [id]: false });
                    if (props.isArchived === true) {
                        props.onRemoveItem(itemId)
                    } else {
                        props.onRemoveItemDots(itemId)
                    }

                } else {
                    alert(json.result.message)
                    setButtonDisabledDots({ [id]: false });
                }

            } catch (er) {

                setButtonDisabledDots({ [id]: false });
                //console.log("There was a problem or the request was cancelled." + er)
            }
        }

        //console.log(showToast)

    }


    const toggleFeatured = async (itemId, newIsFeatured, id) => {
        const ourRequest = Axios.CancelToken.source();
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + auth.currentUser.accessToken
        }
        setButtonDisabledFeatured({ [id]: true });
        try {
            const response = await Axios.post(updateMarketplace_URL, {
                data: {
                    "marketPlaceId": itemId,
                    "isFeatured": newIsFeatured
                }
            },
                { cancelToken: ourRequest.token }
            );
            const json = await response.data;
            if (json.result.status === 200) {

                // Update the success state for this button
                setSuccessStates((prev) => ({
                    ...prev,
                    featured: {
                        ...prev.featured,
                        [id]: newIsFeatured // Mark this button as successful
                    }
                }));

                //data.isFeatured = newIsFeatured
                setButtonDisabledFeatured({ [id]: false });

                setData((prevItems) =>
                    prevItems.map((item) =>
                        item.mktPlaceId === itemId ? { ...item, isFeatured: newIsFeatured } : item
                    )
                );

            } else {
                alert(json.result.message)
                setButtonDisabledFeatured({ [id]: false });
            }
        } catch (er) {

            setButtonDisabledFeatured({ [id]: false });
            //console.log("There was a problem or the request was cancelled." + er)
        }

        //console.log(showToast)

    }

    //togglePromoted

    const togglePromoted = async (itemId, newIsPromoted, id) => {

        setData((prevItems) =>
            prevItems.map((item) =>
                item.mktPlaceId === itemId ? { ...item, isPromoted: newIsPromoted } : item
            )
        );

        const ourRequest = Axios.CancelToken.source();
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + auth.currentUser.accessToken
        }
        setButtonDisabledPromoted({ [id]: true });
        try {
            const response = await Axios.post(updateMarketplace_URL, {
                data: {
                    "marketPlaceId": itemId,
                    "isPromoted": newIsPromoted
                }
            },
                { cancelToken: ourRequest.token }
            );
            const json = await response.data;
            if (json.result.status === 200) {

                // Update the success state for this button
                setSuccessStates((prev) => ({
                    ...prev,
                    promoted: {
                        ...prev.promoted,
                        [id]: newIsPromoted // Mark this button as successful
                    }
                }));

                data.isPromoted = newIsPromoted
                setButtonDisabledPromoted({ [id]: false });
            } else {
                alert(json.result.message)
                setButtonDisabledPromoted({ [id]: false });
            }
        } catch (er) {

            setButtonDisabledPromoted({ [id]: false });
            //console.log("There was a problem or the request was cancelled." + er)
        }

        //console.log(showToast)

    }

    const generateDynamicLink = async (itemId, id) => {

        const marketPlaceDotDynamicLink_URL = process.env.REACT_APP_BACKENDURL + 'marketPlaceDotDynamicLink';

        const ourRequest = Axios.CancelToken.source();
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + auth.currentUser.accessToken
        }
        setButtonDisabledDynamicLink({ [id]: true });
        try {
            const response = await Axios.post(marketPlaceDotDynamicLink_URL, {
                data: {
                    "mktPlace_id": itemId
                }
            },
                { cancelToken: ourRequest.token }
            );
            const json = await response.data;
            if (json.result.status === 200) {

                setDynamicLinks((prevLinks) => ({
                    ...prevLinks,
                    [id]: json.result.dynamicLink,
                }));

                //setDynamicLink(json.result.dynamicLink)
                setButtonDisabledDynamicLink({ [id]: false });
            } else {
                alert(json.result.message)
                setButtonDisabledDynamicLink({ [id]: false });
            }
        } catch (er) {

            setButtonDisabledDynamicLink({ [id]: false });
            //console.log("There was a problem or the request was cancelled." + er)
        }

        //console.log(showToast)

    }

    // Function to copy dynamic link to clipboard
    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link);
        // Optionally provide feedback to the user that the link has been copied
        alert('Dynamic link copied to clipboard!');
    };



    return (
        <>
            <table className="table border mb-0">
                <thead className="table-light fw-semibold">
                    <tr className="align-middle">
                        <th className="text-center">
                            <i className="icon fa fa-users" aria-hidden="true"></i>

                        </th>
                        <th>User</th>
                        <th className="text-center">Title</th>
                        <th className="text-center">Category</th>
                        <th className="text-center">MarketPlace ID</th>
                        <th className="text-center">Price</th>
                        <th className="text-center">createdAt</th>
                        <th className="text-center">isPopular</th>
                        <th className="text-center">isFeatured</th>
                        <th className="text-center">isPromoted</th>
                        <th className="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>

                    {data.length === 0 && typeof data === 'undefined' &&
                        <tr>
                            <td colspan="7">
                                <div className="text-center margin-top-10">No Result!</div>
                            </td>
                        </tr>

                    }

                    {data.map((item, index) => (
                        <tr className="align-middle" key={Math.random()}>
                            <td className="text-center">
                                <div className="avatar avatar-md">
                                    <img className="avatar-img-40" src={typeof item.userAvatar === 'undefined' || item.userAvatar === '' || item.userAvatar === null ? defaultAvatar : item.userAvatar} alt="avatar" />
                                </div>
                            </td>
                            <td>
                                <div>{typeof item.creator === 'undefined' || item.creator === null ? 'Anonmous' : item.creator}</div>

                            </td>
                            <td>
                                {typeof item.dotTitle === 'undefined' || item.dotTitle === null ? 'N/A' : item.dotTitle}

                            </td>

                            <td>
                                {typeof item.category === 'undefined' || item.category === null ? 'N/A' : item.category}

                            </td>

                            <td>
                                <Link rel="noopener noreferrer" to={`/dots-details/${item.mktPlaceId}`} >
                                    {item.mktPlaceId}
                                </Link>

                            </td>
                            <td>
                                {freeDots(item.price)}

                            </td>
                            <td>
                                <div className="clearfix">
                                    <div className="float-start">
                                        {formatWithMonthName(item.createdAt)}
                                    </div>
                                </div>

                            </td>


                            <td className="text-center">
                                <div className="clearfix">
                                    <div className="float-start">
                                        {(typeof item.isPopular === 'undefined' || item.isPopular === false) ? (
                                            <p>No</p>
                                        ) : (
                                            <p>Yes</p>
                                        )}

                                    </div>
                                </div>

                            </td>

                            <td className="text-center">
                                <div className="clearfix">
                                    <div className="float-start">
                                        {/* {console.log("isFeatured:", item.isFeatured)} */}

                                        {(typeof item.isFeatured === 'undefined' || item.isFeatured === false) ? (
                                            <p>No</p>
                                        ) : (
                                            <p>Yes</p>
                                        )}

                                    </div>
                                </div>

                            </td>


                            <td className="text-center">
                                <div className="clearfix">
                                    <div className="float-start">
                                        {(typeof item.isPromoted === 'undefined' || item.isPromoted === false) ? (
                                            <p>No</p>
                                        ) : (
                                            <p>Yes</p>
                                        )}

                                    </div>
                                </div>

                            </td>
                            {/*onClick={storeProfileData.bind(item)} */}

                            <td>
                                <table>
                                    <tr className="button-font-size text-center">
                                        <td>
                                            <button className="btn btn-primary" onClick={() => openModal(item, index)}>
                                                Manage Actions
                                            </button>
                                        </td>

                                        {/* <td>
                                        <div className="dropdown">
                                            <button onClick={() => toggleDisable(item.mktPlaceId, !item.isActive, index)} className={"btn p-2  btn-primary " + (isButtonDisabledDots[index] ? 'loader-btn' : '')} type="button" data-coreui-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {item.isActive ? 'Disable Dots' : 'Enable Dots'}
                                            </button>
                                        </div>

                                    </td>

                                    {item.isActive === true &&
                                        <td>
                                            <div className="dropdown">
                                                <button onClick={() => togglePopularity(item.mktPlaceId, !item.isPopular, index)} className={"btn p-2  btn-primary " + (isButtonDisabledPopular[index] ? 'loader-btn' : '')} type="button" data-coreui-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {item.isPopular ? 'Remove popular' : 'Set as popular'}
                                                </button>
                                            </div>

                                        </td>
                                    }

                                    {item.isActive === true &&
                                        <td>
                                            <div className="dropdown">
                                                <button onClick={() => toggleFeatured(item.mktPlaceId, !item.isFeatured, index)} className={"btn p-2  btn-primary " + (isButtonDisabledFeatured[index] ? 'loader-btn' : '')} type="button" data-coreui-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {item.isFeatured ? 'Remove Featured' : 'Set as Featured'}
                                                </button>
                                            </div>

                                        </td>
                                    }

                                    <td>
                                        <div className="dropdown">
                                            {!dynamicLinks[index] ? (
                                                <button onClick={() => generateDynamicLink(item.mktPlaceId, index)} className={"btn p-2  btn-primary " + (isButtonDisabledDynamicLink[index] ? 'loader-btn' : '')} type="button" data-coreui-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Dynamic Link
                                                </button>
                                            ) : (
                                                <button className="btn p-2  btn-success" onClick={() => copyToClipboard(dynamicLinks[index])}>{dynamicLinks[index]}</button>
                                            )}
                                        </div>

                                    </td> */}

                                    </tr>
                                </table>

                            </td>
                        </tr>
                    ))
                    }
                </tbody >

            </table >

            {selectedItem && (
                <ActionModal
                    item={selectedItem}
                    index={modalIndex}
                    show={showModal}
                    handlers={{
                        toggleDisable,
                        togglePopularity,
                        toggleFeatured,
                        togglePromoted,
                        generateDynamicLink,
                        copyToClipboard,
                    }}
                    state={{
                        isButtonDisabledDots,
                        isButtonDisabledPopular,
                        isButtonDisabledFeatured,
                        isButtonDisabledPromoted,
                        isButtonDisabledDynamicLink,
                        dynamicLinks,
                        successStates
                    }}
                    onClose={closeModal}
                />
            )}
        </>
    );
}

export default MarketplaceList;