import React, { useEffect, useState } from "react";
import DotsCarousel from "./DotsCarousel"; // Ensure you have this component
import Axios from 'axios';
import ReactGA from "react-ga4";
import loader_img from '../assets/images/loader_img.gif';

const ExploreDotCategory = () => {

    const [dotsData, setDotsData] = useState([]); // State for dots
    const [loading, setLoading] = useState(true); // Loading state
    const [selectedCategory, setSelectedCategory] = useState(""); // Currently selected category
    //const App_url = 'https://learnee.page.link/rniX'

    const categories = [
        { name: "Health and Nutrition", hideOnMobile: false },
        { name: "Food & Recipes", hideOnMobile: false },
        { name: "Travel & Tourism", hideOnMobile: true },
        { name: "Education", hideOnMobile: false },
        { name: "Cultural and Religious", hideOnMobile: true },
        { name: "Entertainment", hideOnMobile: true },
        { name: "Productivity", hideOnMobile: false },
        { name: "Lifestyle", hideOnMobile: false },
        { name: "Health and Wellness", hideOnMobile: true },
        { name: "Self-improvement", hideOnMobile: false },
    ];


    const categoryColors = {
        "Health and Nutrition": "#F95738",
        "Food & Recipes": "#F95738",
        "Travel & Tourism": "#4EA5D9",
        "Education": "#083D77",
        "Cultural and Religious": "#F71735",
        "Entertainment": "#B20D30",
        "Productivity": "#AA7DCE",
        "Lifestyle": "#B20D30",
        "Health and Wellness": "#B20D30",
        "Self-improvement": "#AA7DCE",
    };

    const generateAskVaSiDotsWebUrl = process.env.REACT_APP_BACKENDURL + 'askVaSiPublicApi';

    const defaultPayload = {
        pageSize: 10,
        sortBy: "views",
        sortOrder: "desc",
        categories: "",
        isRecommended: false,
        query: "",
        pageOffset: 0,
        request_type: 'dots_categories'
    };

    // Fetch dots by category
    const fetchDots = async (category) => {
        setLoading(true);
        try {

            const payload = { ...defaultPayload, categories: category || "" }; // Add category filter
            const response = await Axios.post(
                generateAskVaSiDotsWebUrl,
                {
                    data: payload,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            //console.log("dotsData==", response.data.result.dots)

            setDotsData(response.data.result?.dots || []); // Update dots data


        } catch (error) {
            console.error("Error fetching dots:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch default dots on initial render
    useEffect(() => {
        fetchDots(); // No category initially
    }, []);

    const handleCategoryClick = (category) => {
        if (selectedCategory === category) return;
        setSelectedCategory(category); // Update selected category
        handleButtonClick(category, "Explore Category")
        fetchDots(category); // Fetch dots based on selected category
    };

    const dotsWithViewAll = [
        ...dotsData,
        { isViewAll: true }, // Add "View All" as the last card
    ];

    const handleButtonClick = (action, label) => {
        // Track the button click event
        ReactGA.event({
            category: "Button",
            action: action, //"Download Now",
            label: label //"VaSi App Link",
        });
    };


    return (

        <section className="explore-dots-section">
            <span className="badge bg-warning-subtle text-dark rounded-pill">MARKETPLACE</span>
            <div className="section-title">Dots for Every Aspect of Life</div>
            <p className="section-subtitle">
                Our marketplace isn’t limited to one field—tap into expert insights on a wide array of topics:
            </p>
            <div className="category-buttons">
                {categories.map((category, index) => (
                    <button
                        key={index}
                        onClick={() => handleCategoryClick(category.name)}
                        className={`${category.hideOnMobile ? "hide-category-mobile" : ""} 
                                    ${selectedCategory === category.name ? "active" : ""}`}
                        style={{
                            backgroundColor: selectedCategory === category.name ? categoryColors[category.name] : "transparent",
                            color: selectedCategory === category.name ? "#fff" : "#000",
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = categoryColors[category.name]}
                        onMouseLeave={(e) => e.target.style.backgroundColor = selectedCategory === category.name ? categoryColors[category.name] : "transparent"}
                    >
                        {category.name}
                    </button>
                ))}
            </div>

            {loading &&
                <div className="carousel-loader-container">
                    <img
                        src={loader_img}
                        alt="Loading..."
                        className="carousel-loader"
                    />
                </div>
            }
            <div className="carousel-container">
                {/* Import and use the DotsCarousel */}
                <DotsCarousel dotsData={dotsWithViewAll} />
                {/* <div className="view-all">
                    <a rel="noopener noreferrer" href={App_url}>
                        <button onClick={() => handleButtonClick("Explore More", "VaSi App Link")} className='cta-button'>Explore More</button>
                    </a>
                </div> */}
            </div>


        </section>
    )
}

export default ExploreDotCategory;