import React from 'react';
//import HeaderLoggedOut from "../HeaderLoggedOut"
//import FooterNew from "../FooterNew"
import AboutUsCarousel from '../AboutUsCarousel';
import NavBar from '../NavBar';
import LandingPageFooter from '../LandingPageFooter';
//import Page from "./Page"
function PrivacyPolicyUSA() {
    // useEffect(() => {
    //     document.title = `About Us | VasiApp`
    //     window.scrollTo(0, 0)
    // }, [])
    return (
        <div className="askvasi-home">
            <NavBar />
            <div className="container outer-page">
                <header className="masthead_inner">
                    <div className="container px-5 text-center">
                        {/* <h1>About Us</h1> */}
                    </div>
                </header>
                <aside className="inner_pages">
                    <div className="container px-5">
                        <div className="row gx-5">
                            <p>
                                Our lives are increasingly hectic and distracted. We deal with a flood of information that can sometimes be overwhelming and make us lose sight of what really matters to us. Digital content is often distracting and adds to our cognitive clutter. All this makes it difficult for us to get things done, especially our long-term goals. The human mind can't track too many things at the same time. Experts say that seven is about the most things we can keep in our minds at any time.
                            </p>

                            <p>
                                What if we had a personal assistant, an extension of our own minds, that helped us keep track of what matters to us? An aide that prompts us with the right information, exactly when we needed it, at the right time, place and situation? What if we had a trusted, safe way to bring all that matters to us, into the moment when it matters? VaSi is that way. I built VaSi because I was looking for such an aide. As an entrepreneur and a mom, I often find myself dealing with a flood of information and decisions that would sometimes keep me from focusing on what really matters. I built VaSi to help me, and all of us, be mindful in everything we do. It is an extension of our mind, an app that unobtrusively tracks what matters to us and gently reminds us when and where we need to be reminded.
                            </p>

                           
                                <AboutUsCarousel />
                            


                        </div>
                    </div>
                </aside>
            </div>
           <LandingPageFooter />
        </div>
    );
}

export default PrivacyPolicyUSA;
