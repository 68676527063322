import React, { useState, useEffect, useRef } from "react";
import "../assets/css/consumerPhoneCarousel.css";

import phone_1 from '../assets/images/consumers/phone-1.mp4';
import phone_2 from '../assets/images/consumers/phone-2.mp4';
import phone_3 from '../assets/images/consumers/phone-3.mp4';
import loaderImg from '../assets/images/loader.gif';

const ConsumerPhoneCarousel = () => {
    const videoRefs = useRef([]);
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [videoDurations, setVideoDurations] = useState([6000, 6000, 6000]); // Default timing

    const videoFrames = [
        phone_1,
        phone_2,
        phone_3
    ];

    useEffect(() => {
        if (videoRefs.current[activeIndex]) {
            // Pause all videos first
            videoRefs.current.forEach((video, index) => {
                if (video && index !== activeIndex) {
                    video.pause();
                    video.currentTime = 0;
                }
            });

            const duration = (videoRefs.current[activeIndex].duration * 1000 + 100) || 6100; // Add 1 sec buffer, fallback to 11s
            videoRefs.current[activeIndex].play().catch(error => console.error("Play error:", error));
            setTimeout(() => {
                setActiveIndex((prevIndex) => (prevIndex + 1) % videoFrames.length);
            }, duration);
        }
    }, [activeIndex, videoFrames.length]);

    useEffect(() => {
        videoRefs.current.forEach((video, index) => {
            if (video) {
                video.onloadedmetadata = () => {
                    setVideoDurations((prevDurations) => {
                        const updatedDurations = [...prevDurations];
                        updatedDurations[index] = (video.duration * 1000 + 100) || 6100;
                        return updatedDurations;
                    });
                };
            }
        });
    }, []);

    return (
        <div className="phone-container">
            {loading && (
                <div className="loader-container">
                    <img src={loaderImg} alt="Loading..." className="loader" />
                </div>
            )}
            {videoFrames.map((video, index) => {
                let position = "hidden";
                let isActive = index === activeIndex;

                if (isActive) {
                    position = "center";
                } else if (index === (activeIndex + 1) % videoFrames.length) {
                    position = "left";
                } else if (index === (activeIndex + 2) % videoFrames.length) {
                    position = "right";
                }

                return (
                    <video
                        key={index}
                        ref={(el) => videoRefs.current[index] = el}
                        src={video}
                        className={`phone-frame ${position}`}
                        muted
                        loop
                        playsInline
                        preload="metadata"
                    />
                );
            })}
        </div>
    );
};

export default ConsumerPhoneCarousel;