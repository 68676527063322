import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { getAuth, signOut } from "firebase/auth";
import { Link, useLocation } from "react-router-dom"
import '../../assets/css/style_admin.css';
import adminAvatar from '../../assets/images/admin_avatar.png'

function DashboardHeader() {
    const location = useLocation();
    const auth = getAuth();
    const signOutUser = (auth) => {
        localStorage.clear();
        signOut(auth);
    }

    const handleClick = (e) => {
        e.preventDefault(); // Prevent the default link behavior
        // Remove the key-value pair in local storage
        localStorage.removeItem('mktPlaceId');
        // Redirect to another page
        window.location.href = e.target.href;

    };

    const [showSubmenu, setShowSubmenu] = useState(false);

    const [showSubmenuNotification, setShowSubmenuNotification] = useState(false);

    const handleMouseOver = () => {
        setShowSubmenu(true);
    };

    const handleMouseLeave = () => {
        setShowSubmenu(false);
    };

    const handleMouseOverNotification = () => {
        setShowSubmenuNotification(true);
    };

    const handleMouseLeaveNotification = () => {
        setShowSubmenuNotification(false);
    };




    //const pathSegments = location.pathname.split('/').filter(Boolean);

    //console.log(location.pathname);
    return (

        <header className="header header-sticky mb-4">
            <div className="container-fluid">
                {/* <button className="header-toggler px-md-0 me-md-3" type="button" onClick="coreui.Sidebar.getInstance(document.querySelector('#sidebar')).toggle()">
                    <i className="icon icon-lg fa fa-bars"></i>

                </button> */}
                {/* <Link className="header-brand d-md-none" to="#">

                </Link> */}
                <ul className="header-nav d-md-flex" >
                    <li className={`nav-item ${location.pathname === '/dashboard/' || location.pathname === '/dashboard' ? 'active' : ''}`}><Link className="nav-link" to="/dashboard/">Dashboard</Link></li>

                    {/* <li className={`nav-item ${location.pathname === '/dashboard/payouts' ? 'active' : ''}`}><Link className="nav-link" to="/dashboard/payouts">All Payouts</Link></li> */}


                    <li onMouseOver={handleMouseOver}
                        onMouseLeave={handleMouseLeave} className={`nav-item ${location.pathname === '/dashboard/payouts' || location.pathname === '/dashboard/referral-payouts' ? 'active' : ''}`}>
                        {/* <Link class="nav-link" href="/dashboard/payouts">All Payouts</Link> */}
                        <span className="nav-link cursor-pointer">All Payouts</span>
                        {/* <!-- Submenu for Payouts --> */}
                        {showSubmenu &&
                            <ul className="submenu">
                                <li><Link to="/dashboard/payouts">Dot Earnings Payouts</Link></li>
                                <li><Link to="/dashboard/referral-payouts">Referral Payouts</Link></li>
                            </ul>
                        }
                    </li>

                    <li className={`nav-item ${location.pathname === '/dashboard/marketplacedots' ? 'active' : ''}`}>
                        <Link onClick={handleClick} className="nav-link" to="/dashboard/marketplacedots">Marketplace Dots</Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/dashboard/archived-marketplace-dots' ? 'active' : ''}`}>
                        <Link onClick={() => {
                            // Remove an item from localStorage
                            localStorage.removeItem('mktPlaceId');
                        }} className="nav-link" to="/dashboard/archived-marketplace-dots">Archived Marketplace Dots</Link>
                    </li>

                    <li onMouseOver={handleMouseOverNotification}
                        onMouseLeave={handleMouseLeaveNotification} className={`nav-item ${location.pathname === '/dashboard/send-push-notification' || location.pathname === '/dashboard/send-promotional-push-notification' ? 'active' : ''}`}>
                        <span className="nav-link cursor-pointer">Notification</span>
                        {/* <!-- Submenu for Payouts --> */}
                        {showSubmenuNotification &&
                            <ul className="submenu">
                                <li><Link to="/dashboard/send-push-notification">Send Push Notification</Link></li>
                                <li><Link to="/dashboard/send-promotional-push-notification">Send Promotional Push Notification</Link></li>
                            </ul>
                        }
                    </li>

                    {/* <li className={`nav-item ${location.pathname === '/dashboard/send-push-notification' ? 'active' : ''}`}>
                        <Link onClick={handleClick} className="nav-link" to="/dashboard/send-push-notification">Send Push Notification</Link>
                        <ul className="submenu">
                            <li><Link to="/dashboard/send-promotional-push-notification">Dot Earnings Payouts</Link></li>                          
                        </ul>
                    </li> */}
                    {/*<li className="nav-item"><Link className="nav-link" to="#">Settings</Link></li> */}
                </ul>
                <ul className="header-nav ms-auto">
                    {/* <li className="nav-item"><Link className="nav-link" to="#">
                        <i className="icon icon-lg fa fa-bell" aria-hidden="true"></i>

                    </Link></li>
                    <li className="nav-item"><Link className="nav-link" to="#">
                        <i className="icon icon-lg fa fa-list" aria-hidden="true"></i>

                    </Link></li>
                    <li className="nav-item"><Link className="nav-link" to="#">
                        <i className="icon icon-lg fa fa-envelope-open" aria-hidden="true"></i>

                    </Link></li> */}

                </ul>
                <ul className="header-nav ms-3">
                    <li className="nav-item dropdown">

                        <Dropdown>

                            <Dropdown.Toggle variant="transparent" id="dropdown-basic" className="nav-link py-0">
                                <div className="avatar avatar-md"><img className="avatar-img-40" src={adminAvatar} alt="admin" /></div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <div className='padding-15'>Admin</div>
                                <Dropdown.Item to='/login' onClick={() => signOutUser(auth)}>Logout</Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>

                        {/* <Link className="nav-link py-0" data-coreui-toggle="dropdown" to="#" role="button" aria-haspopup="true" aria-expanded="false">
                            <div className="avatar avatar-md"><img className="avatar-img" src="https://coreui.io/demos/bootstrap/4.2/free/assets/img/avatars/8.jpg" alt="admin" /></div>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end pt-0">
                            <div className="dropdown-header bg-light py-2">
                                <div className="fw-semibold">Settings</div>
                            </div>
                            <Link className="dropdown-item" to="#">Profile</Link>
                            <Link className="dropdown-item" to="#">Settings</Link>
                            <Link className="dropdown-item" to="#">Payments
                            <span className="badge badge-sm bg-secondary ms-2">42</span></Link>
                            <Link className="dropdown-item" to={() => false} onClick={() => signOutUser(auth)}>Logout</Link>
                        </div> */}
                    </li>
                </ul>
            </div>
            <div className="header-divider"></div>
            <div className="container-fluid">


                {/* <nav>
                    <ol className="breadcrumb my-0 ms-2">
                        {pathSegments.map((segment, index) => (
                            <li key={index} className="breadcrumb-item">
                                <Link to={`/${pathSegments.slice(0, index + 1).join('/')}`}>
                                    {segment}
                                </Link>
                            </li>
                        ))}
                    </ol>
                </nav> */}

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-0 ms-2">

                        {(location.pathname === "/dashboard" || location.pathname === "/dashboard/") &&
                            <li className="breadcrumb-item active"><span>Dashboard</span></li>
                        }
                        {location.pathname.startsWith("/dashboard/profile") &&
                            <>  <li className="breadcrumb-item">
                                <span><Link className='nav-link' to="/dashboard">Dashboard</Link></span>
                            </li>
                                <li className="breadcrumb-item active"><span><Link className='breadcrumbLink' to="/dashboard/payouts/">Payouts</Link></span></li>
                                <li className="breadcrumb-item active"><span>Profile</span></li>
                            </>
                        }
                        {(location.pathname === "/dashboard/payouts" || location.pathname === "/dashboard/payouts/") &&
                            <>
                                <li className="breadcrumb-item">
                                    <span><Link className='nav-link' to="/dashboard">Dashboard</Link></span>
                                </li>
                                <li className="breadcrumb-item active"><span>Dot Earnings Payouts</span></li>
                            </>
                        }

                        {(location.pathname === "/dashboard/referral-payouts" || location.pathname === "/dashboard/referral-payouts/") &&
                            <>
                                <li className="breadcrumb-item">
                                    <span><Link className='nav-link' to="/dashboard">Dashboard</Link></span>
                                </li>
                                <li className="breadcrumb-item active"><span>Referral Payouts</span></li>
                            </>
                        }

                        {(location.pathname === "/dots-details") &&
                            <>
                                <li className="breadcrumb-item">
                                    <span><Link className='nav-link' to="/marketplacedots">Marketplace Dots</Link></span>
                                </li>
                                <li className="breadcrumb-item active"><span>Details</span></li>
                            </>
                        }


                        {/* {(location.pathname === "/dashboard/send-push-notification") &&
                            <>
                                <li className="breadcrumb-item"><span>Notification</span></li>
                                <li className="breadcrumb-item active">
                                    <span><Link className='nav-link' to="/send-push-notification">Send Push Notification</Link></span>
                                </li>

                            </>
                        } */}


                    </ol>
                </nav>
            </div>
        </header>
    );
}

export default DashboardHeader;