import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import NavBar from './NavBar'



import '../assets/css/home_new.css';
import '../assets/css/home.css';
import '../assets/css/animation.css'
import '../assets/css/landingpage.css';
import '../assets/css/creators.css';
import '../assets/css/consumers.css';
//import LandingPageFooter from './LandingPageFooter';
//import DotsImagesMobileCarousel from './BlurredImages';


import piggy_bank from '../assets/images/landing_v2/piggy-bank.gif';
import face_mask from '../assets/images/landing_v2/face-mask.gif';

import recipe_book from '../assets/images/landing_v2/recipe-book.gif';
import alarm_clock from '../assets/images/landing_v2/alarm-clock.gif';

import family_time from '../assets/images/landing_v2/family-time.png';
import books_stack from '../assets/images/landing_v2/books-stack.png';
import travel_bag from '../assets/images/landing_v2/travel-bag.png';
import language_learning from '../assets/images/landing_v2/language-learning.png';
import friendly_ghost from '../assets/images/landing_v2/friendly-ghost.png';

import section_3_1 from '../assets/images/consumers/saved_a_trip.png';
import section_3_2 from '../assets/images/consumers/advice.png';


import fire_icon from '../assets/images/consumers/fire_icon.png';
import list_dot from '../assets/images/consumers/list_dot.png';
import connector_left from '../assets/images/consumers/connector_left.png';
import connector_right from '../assets/images/consumers/connector_right.png';
import ExploreDotCategory from './ExploreDotCategory';
import ConsumerPhoneCarousel from './ConsumerPhoneCarousel';
import LandingPageFooterCosumer from './LandingPageFooterCosumer';

//import TextSlider from './TextSlider';

// App Component
function ConsumerPage() {


    const App_url = 'https://learnee.page.link/rniX'


    const gridItems = [
        { id: 1, className: "piggy-bank", type: "gif", src: piggy_bank, alt: "Piggy Bank" },
        { id: 2, className: "books-stack", type: "image", src: books_stack, alt: "Books Stack" },
        { id: 3, className: "face-mask", type: "gif", src: face_mask, alt: "Face Mask GIF" },
        { id: 4, className: "travel-bag", type: "image", src: travel_bag, alt: "Travel Bag" },
        { id: 5, className: "recipe-book", type: "gif", src: recipe_book, alt: "Recipe Book GIF" },
        { id: 6, className: "language-learning", type: "image", src: language_learning, alt: "Language Learning" },
        { id: 7, className: "friendly-ghost", type: "image", src: friendly_ghost, alt: "Friendly Ghost GIF" },
        { id: 8, className: "family-time", type: "image", src: family_time, alt: "Family Time" },
        { id: 9, className: "alarm-clock", type: "gif", src: alarm_clock, alt: "Alarm Clock GIF" },
    ];



    useEffect(() => {
        const navbar = document.querySelector('.navbar');
        if (navbar) {
            navbar.classList.remove('hidden');
        }
    }, []);


    const handleButtonClick = (action, label) => {
        // Track the button click event
        ReactGA.event({
            category: "Button",
            action: action, //"Download Now",
            label: label //"VaSi App Link",
        });
    };


    return (
        <div className="askvasi-home landing-page download-page consumer-page" id='askvasi-landing'>
            {/* Navbar */}
            <NavBar />



            {/* Page 6 and beyond */}
            <div className="slide-up-overlay-slide-6 page-6 active" id="page-6">
                {/* Container for the two columns */}

                <div className="hero-section-container">
                    {/* Left Column: Overlay content */}
                    <div className="overlay-content-hero-section">

                        <div className='hero-title'><span className='hero-title-1'>Learn from the Best, Grow Faster</span></div>
                        <p>Discover bite‑sized wisdom and proven insights from people who’ve already conquered your challenges.</p>
                        <a rel="noopener noreferrer" href={App_url}>
                            <button onClick={() => handleButtonClick("Discover Dots", "VaSi App Link")} className="cta-button">Discover Dots</button></a>
                    </div>

                    {/* Right Column: Blurred images container blurred-images-container-hero-section*/}
                    <div className="blurred-images-container-hero-section">
                        <div className="image-5">
                            <ConsumerPhoneCarousel />
                        </div>
                    </div>

                </div>
            </div>

            {/* Quote Section */}
            <section className="bg-dark text-white py-5 text-center">
                <blockquote className="fst-italic">“Everything not saved will be lost.”</blockquote>
                <p>- Tiago Forte</p>
            </section>


            {/* Why VaSi Section */}
            <section className="text-center why-vasi">
                <span className="badge bg-warning-subtle text-dark rounded-pill">WHY VASI</span>
                <div className="advice-heading mt-3">Don’t Let Great Advice Slip Away</div>
                <p className="advice-paragraph mt-2">Every insight deserves a home. Secure Dots—real advice you can count on.</p>
            </section>


            <section className="beyond-chat-section section-3">
                <div className="content-container">
                    <div className="image-container">
                        <div className="beyond-title beyond-title-1">Saved a tip and Forgot about it? We’ve all been there.</div>
                        <div>
                            <img loading="lazy"
                                src={section_3_1}
                                alt="VaSi App Preview"
                                className="beyond-chat-image"
                            />
                        </div>

                    </div>
                    <div className="text-container">
                        <div className="beyond-title beyond-title-2"><span className="beyond-title-p">No more forgetting.
                            Stop losing great advice. Let Vasi help you use it.</span></div>
                        <div>
                            <img loading="lazy"
                                src={section_3_2}
                                alt="VaSi App Preview"
                                className="beyond-chat-image"
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* How It Helps Section */}
            <section className="py-5 text-center bg-light how-it-helps">
                <span className="badge bg-warning-subtle text-dark rounded-pill">HOW IT HELPS</span>
                <div className="advice-heading mt-3">CODE Your Way To Clarity</div>
                <p className="advice-paragraph mt-2">Our system helps you transform raw experience into actionable guidance.</p>
            </section>


            {/* CODE System Steps */}
            <section className="bg-warning-subtle py-5 code-system">
                <div className="container text-center">
                    <div className="fw-bold">With CODE you can:</div>
                    <div className="d-flex flex-column align-items-center mt-4 position-relative">
                        <div className="p-3 bg-white paragraph-1 position-relative step-box">Capture: Snag the moment’s best advice.
                            <img className='connector-img right' src={connector_left} loading="lazy" alt="Blurred Dots 5" />
                        </div>

                        <div className="p-3 bg-white paragraph-2 mt-3 position-relative step-box">Organize: Sort wisdom into clear, actionable categories.
                            <img className='connector-img left' src={connector_right} loading="lazy" alt="Blurred Dots 5" />
                        </div>
                        <div className="p-3 bg-white paragraph-3 mt-3 position-relative step-box">Distill: Extract the core insights.
                            <img className='connector-img right-2' src={connector_left} loading="lazy" alt="Blurred Dots 5" />
                        </div>
                        <div className="p-3  bg-white paragraph-4  mt-3 position-relative step-box">Express: Turn these insights into actions.</div>
                    </div>
                </div>
            </section>


            {/* Quote Section */}
            <section className="bg-dark text-white py-5 text-center beyound-limit">
                <blockquote className="fst-italic">"We extend beyond our limits, not by reviving our brains like a machine, but by strewing our world with rich materials and weaving them into our thoughts."</blockquote>                
            </section>


            <ExploreDotCategory />



            {/* Testimonials Section */}
            <section className="testimonials-section text-center">
                <div className="badge bg-warning-subtle text-dark rounded-pill">TESTIMONIALS</div>
                <div className="advice-heading mt-3">Dots Loved By Many</div>
                <p className="advice-paragraph advice-paragraph-mobile mt-2">Empowering you to act with confidence.</p>
                <div className="d-flex justify-content-center gap-5 my-4">
                    <div>
                        <div><img className='fire-icon' src={fire_icon} loading="lazy" alt="Blurred Dots 5" /></div>
                        <span className="testimonials-number">10K+</span>
                        <p className="testimonials-text">Users Helped</p>
                    </div>
                    <div>
                        <div><img className='list-icon' src={list_dot} loading="lazy" alt="Blurred Dots 5" /></div>
                        <span className="testimonials-number">5K+</span>
                        <p className="testimonials-text">Collection of Dots</p>
                    </div>
                    <div>
                        <div><img className='list-icon' src={list_dot} loading="lazy" alt="Blurred Dots 5" /></div>
                        <span className="testimonials-number">10K+</span>
                        <p className="testimonials-text">Dots Sold</p>
                    </div>
                </div>
            </section>


            <div className='container how-to-create'>


                <div className="explore-dots-section ready-to-unlock">

                    <div className="section-title">Ready to Unlock Proven Wisdom?</div>
                    <div className="section-sub-title">Thousands trust our marketplace for actionable advice from real experts. Find your Dot and take the next step.</div>

                    <div className="carousel-container">

                        <div className="view-all">
                            <a rel="noopener noreferrer" href={App_url}>
                                <button onClick={() => handleButtonClick("Explore Marketplace", "VaSi App Link")} className='cta-button'>Explore Marketplace</button>
                            </a>
                        </div>

                    </div>
                    <div className='footer_bg_middle'></div>

                </div>

            </div>
            <LandingPageFooterCosumer />

        </div>

    );
}
export default ConsumerPage;